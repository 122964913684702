<template>
    <div class="container">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col">
    
        <div class="row mt-3">
          <div class="col-12 col-md-6 col-lg-4 offset-md-3 offset-lg-4">
            <form action="./admin-dasbor.html" class="form-signin needs-validation" novalidate>
              <h1 class="h5 mb-3 font-weight-normal text-center text-uppercase mb-4">Administrasi</h1>

              <div class="form-group">
                <label for="email">Email:</label>
                <input type="email" class="form-control" id="email" placeholder="Masukkan alamat email..." name="email" required>
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please fill out this field.</div>
              </div>
              <div class="form-group">
                <label for="pass1">Password:</label>
                <input type="password" class="form-control" id="pass1" name="pass1" placeholder="Masukkan password..." required>
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Please fill out this field.</div>
              </div>

              <button class="btn btn-success btn-block mt-4" type="submit">MASUK</button>
              <router-link to="/" class="btn btn-link btn-block" type="submit" title="Kambali">Kembali</router-link>
            </form>
          </div>
        </div>

      </div><!-- #main-content -->
    </div><!-- .row -->
  </div><!-- .container -->
</template>